import React from "react"
import Slider from "react-slick"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./CareerSlider.scss"


const NextArrow =(props)=>{
  const { className, style, onClick } = props;
return(
  <div
    className={className}
    style={{ ...style, display: "block"}}
    onClick={onClick}
  >
    <i className={`icon right-slide-arrow`}/>
  </div>
)

}

const PrevArrow =(props)=>{
  const { className, style, onClick } = props;
return(
  <div
    className={className}
    style={{ ...style, display: "block"}}
    onClick={onClick}
  >
    <i className={`icon left-slide-arrow`}/>
  </div>
)

}

const CareerSlider = ({ data,count,mobileOnly }) => {
  const { isTabletOnly,isMobile } = useDeviceMedia()

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow:isMobile?1: isTabletOnly ? 2.3 : 4.4,
    slidesToScroll: 1,
    arrows: isMobile?true:false,
    speed:isMobile?500: 4000,
    autoplaySpeed:isMobile?500:4000,
    autoplay: isMobile?false:true,
    cssEase: "linear",
    rtl:count==="1"?true:false,
    nextArrow:<NextArrow/>,
    prevArrow: <PrevArrow/>,
  }




  return (
    <>
    {mobileOnly?
    <div className={`career-slider-wrapper`}>
      <div className="career-slider_container">
        <div className={`image_section_module ${count?"section-"+count:""}`}>
          <Slider {...settings}>
            {data?.map((list, index) => {
              return (
                <div className="image-list">
                  <img src={list?.images?.url} alt="Image Slider - haus & haus"/>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
    :
    <div className={`career-slider-wrapper ${data?.type}`}>
    <div className="career-slider_container">
      <div className={`image_section_module ${count?"section-"+count:""}`}>
        <Slider {...settings}>
          {data?.add_image?.map((list, index) => {
            return (
              <div className="image-list">
                <img src={list?.images?.url} alt="Image Slider - haus & haus"/>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  </div>
  }
    </>
  )
}

export default CareerSlider
