import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const PageWithIcon = props => {
  return (
    <section
      className={`icon-page slider ${props?.count === 3 ? "item3" : ""} ${
        props?.layout
      }`}
      id="why-attend"
    >
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="page-top-block">
              {props.title && <h2>{props.title}</h2>}

              {(props.description?.data?.description || (props?.description && props?.isPreviewEnabled)) && (
                <ContentModule Content={props.description?.data?.description || props.description} />
              )}
            </div>
            {props?.icon_module?.length > 0 ? (
              <div className="page-icon-slider">
                {props?.icon_module?.map((item, i) => {
                  var imagename = "page.icon_section_image.image"

                  let processedImages = JSON.stringify({})
                  // if (props?.imagetransforms) {
                  //   processedImages = props?.imagetransforms
                  // }

                  return (
                    <div className="icon-item">
                      <img
                        src={item?.image?.url}
                        alt={
                          item?.image?.alternativeText
                            ? item?.image?.alternativeText
                            : item.title
                        }
                      />
                      {/* <ImageModule ImageSrc={item?.image} 
                                            altText={`${item?.image?.alternativeText ? item?.image?.alternativeText : item.title ? item.title : ""} icon`}
                                             imagetransforms={processedImages} renderer="pic-src" imagename={imagename} strapi_id={props?.id} classNames="img-fluid banner-img" /> */}
                      <div className="title">{item?.title}</div>
                      {(item.description?.data?.description||(item.description && props?.isPreviewEnabled)) && (
                        <ContentModule
                          Content={item.description?.data?.description || item.description}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      </ScrollAnimation>
    </section>
  )
}

export default PageWithIcon
