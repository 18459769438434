import { graphql } from "gatsby"
import React,{useState,useEffect} from "react"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import CareerSlider from "../components/CareerSlider/CareerSlider"
import GridGallery from "../components/GridGallery/GridGallery"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import LandingBanner from "../components/LandingBanner/LandingBanner"
import LandingContent from "../components/LandingContent/LandingContent"
import PageWithIcon from "../components/PageWithIcon/PageWithIcon"
import SecondaryPageBanner from "../components/SecondaryPageBanner/SecondaryPageBanner"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
// import MortgageCalculator from "../components/MortgageCalculator/MortgageCalculator"
import { Container } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
// import RentalYieldCalculator from "../components/RentalYieldCalculator/RentalYieldCalculator"
import OffplanSearchSlider from "../components/OffplanSearchSlider/OffplanSearchSlider"
import ValuationModule from "../components/ValuationModule/ValuationModule"
import ValNewsSlider from "../components/ValNewsSlider/ValNewsSlider"
import PropertySlider from "../components/PropertySlider/PropertySlider"
import LandingBannerCard from "../components/LandingBannerCard/LandingBannerCard"

const LandingPageTemplate = ({ data, pageContext }) => {
  const { isMobile } = useDeviceMedia()

  const [pageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)

  let popUpData=""

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        seo: "*",
        banner:{
          populate:{
            image:"*",
            cta_1_link:{
              populate:{
                parent:"*"
              }
            },
            cta_2_link:{
              populate:{
                parent:"*"
              }
            },
            cta_3_link:{
              populate:{
                parent:"*"
              }
            }
          }
        },
        add_page_modules: {
          on: {
            'page-modules.image-content': {
              populate: {
                left_right_block: {
                  populate:{
                  image:"*",
                  menu:{
                    populate:{
                      parent:"*"
                    }
                  }
                }
              }
              },
            },
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.accordion":{
              populate:{
                add:{
                  populate:"*"
                }
              }
            },
            "page-modules.guides-cards":{
              populate:{
                items:{
                  populate:{
                    image:"*",
                    link:{
                      populate:{
                        parent:"*"
                      }
                    }
                  }
                }
              }
            },
            "page-modules.icon-content":{
              populate:{
                icon_module:{
                  populate:{
                    image:"*"
                  }
                }
              }
            },
            "page-modules.valuation-image":{
              populate:{
                cta_item:{
                  populate:{
                    link: "*",
                  }
                },
                add_image:{
                  populate:{
                    images: "*"
                  }
                }
              }
            },
            "page-modules.secondary-banner":{
              populate:{
                image:"*"
              }
            },
            "page-modules.global-module":{
              populate:{
                select_module:"*"
              }
            }
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.banner.banner_image = {}
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          myPrevData.banner = previewData?.banner
          myPrevData.banner.image = previewData?.banner?.image
          myPrevData.banner.banner_content = previewData?.banner?.banner_content
          myPrevData.banner.banner_title = previewData?.banner?.banner_title
          myPrevData.banner.cta_1_title = previewData?.banner?.cta_1_title
          myPrevData.banner.cta_1_custom_link = previewData?.banner?.cta_1_custom_link
          myPrevData.banner.cta_1_link = previewData?.banner?.cta_1_link
          myPrevData.banner.cta_2_link = previewData?.banner?.cta_2_link
          myPrevData.banner.cta_2_title = previewData.banner.cta_2_title
          myPrevData.banner.cta_2_custom_link = previewData?.banner?.cta_2_custom_link
          myPrevData.banner.cta_3_custom_link = previewData?.banner?.cta_3_custom_link
          myPrevData.banner.cta_3_title = previewData?.banner?.cta_3_title
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.pdf = previewData?.pdf
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.slug = previewData?.slug
          myPrevData.mobile_image = previewData?.mobile_image
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(pageData => ({ ...pageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  if(pageData?.add_page_modules?.length>0){
     const results=pageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(enablePreview){
    return null
  }

  const isMortgagePage = pageData?.title === "Mortgages";
  const isRentalYield = pageData?.title === "Rental Yield Calculator"

  const getNewsIds = pageData?.add_page_modules?.find(item=> item.__typename === "STRAPI__COMPONENT_COMPONENTS_TEXT_LIST" && 
    (item.item_type === "Mortgage Guides" || item.item_type === "Rental Guides"))
  const getTitle = getNewsIds?.title
  const blogs = data?.allStrapiBlog?.edges;
  const newsIds = getNewsIds ? getNewsIds?.item?.split(",") : ""
  const parseIds = newsIds?.length>0 ? newsIds?.map(item=>parseInt(item)):null
  const filteredNews = blogs?.filter(item=>parseIds?.includes(item?.node?.strapi_id))

  //get offplan Data
  const offplanquery = data?.allStrapiNewDevelopments?.edges;
  const offplanIds =  pageData?.add_page_modules?.find(item=> item.__typename === "STRAPI__COMPONENT_COMPONENTS_TEXT_LIST" &&
    item.item_type === "Off-plan properties")
    const getOffplanTitle = {
      title:offplanIds?.title
    } 

    const splitOffplanId = offplanIds ? offplanIds?.item?.split(",") : ""
    const filteredOffplan = offplanquery?.filter(item=>splitOffplanId?.includes(item?.node?.crm_id))
  //

  //property data
  const filteredProperty = data?.allProperties?.edges;


  return (
    <Layout dark={(isMortgagePage || isRentalYield) ?true:false} popUpData={popUpData} popularSearch={pageData?.select_popular_search?.title}>
    <LandingBanner pageData={pageData} isPreviewEnabled={isPreviewEnabled} />
    {pageData?.add_page_modules?.map((item, i) => {
      return (
        <div>
          {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
          item?.__component ==="components.title-desc")
          && (
            <LandingContent isPreviewEnabled={isPreviewEnabled} data={item} />
          )}
          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" ||
            item?.__component ==="page-modules.valuation-image")
            && !isMortgagePage&&!isRentalYield&&(
            <>
              {item.type === "grid" ? (
                <GridGallery data={item} pageData={pageData} />
              ) :
              isMobile&& item.count==="1"?
              (
                <CareerSlider data={item} pageData={pageData} />
              )
              :!isMobile&&
              <CareerSlider data={item} pageData={pageData} count={item.count} />
              }
            </>
          )}

          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" ||
            item?.__component ==="page-modules.valuation-image")
            && (isMortgagePage || isRentalYield)&&(
              <ValuationModule
                isPageTrue={isMortgagePage || isRentalYield}
                valuationData={item}
                imageData={item}
                PageData={pageData}
                isPreviewEnabled={isPreviewEnabled}
            />
            )}

           {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_PODCAST_CARD" &&
              <LandingBannerCard data={item}/>
           } 


          {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&  
          (item.select_module === "mortgage_guide_slider" || item.select_module === "rental_guide_slider" )&&
            <ValNewsSlider sliderTitle={getTitle} isMortgageNews isPreviewEnabled={isPreviewEnabled}  {...item} data={filteredNews} />
          }

          {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" && (
            item.select_module === "offplan_developer_slider"&&
            <Container>
               <OffplanSearchSlider islandingpage moduleData={getOffplanTitle} offplanData={filteredOffplan} isDynamic/> 
            </Container>
          )} 

          {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" && (
            item.select_module === "property_slider"&&
                <PropertySlider filteredProperty={filteredProperty}/>
          )} 

          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" ||
            item?.__component ==="page-modules.image-content")
            && (
            <ImageContentComponent isTopSpace={isMortgagePage || isRentalYield} isLandingBanner isPreviewEnabled={isPreviewEnabled} data={item} pageData={pageData} />
          )}
          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER" ||
            item?.__component === "page-modules.secondary-banner")
            && (
            <div>
              <SecondaryPageBanner isPreviewEnabled={isPreviewEnabled} data={item} pageData={pageData} />
            </div>
          )}
          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT" ||
            item?.__component ==="page-modules.icon-content")
            &&
            (item?.layout === "page" ||
            item?.layout === "page_vertical_line" ? (
              <PageWithIcon
                id={pageData?.strapi_id}
                imagetransforms={
                  pageData.imagetransforms?.icon_section_image_Transforms
                }
                {...item}
                count={3}
                isPreviewEnabled={isPreviewEnabled}
              />
            ) : null)}


          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" ||
            item?.__component === "page-modules.accordion")
          && (
            <AccordianValuation
              add_toggle={item?.add}
              isPreviewEnabled={isPreviewEnabled}
              title={item?.title}
              content={item?.content}
            />
          )}
          {(item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" ||
            item?.__component ==="page-modules.guides-cards")
            && (
            <GuidesCardComponent isPreviewEnabled={isPreviewEnabled} data={item} />
          )}
        </div>
      )
    })}
  </Layout>
  )
}


export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  let myimgtransforms = ""
  if (PageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      PageData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (PageData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(PageData?.imagetransforms?.metaog_Transforms)
  }


  let getImg= PageData?.banner?.image?.url? PageData?.banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""


  let imageUrl=""

  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }
  else if(myimgtransforms){
    // ogRender()
    const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    imageUrl=ProcessedResult?ProcessedResult["768x397"]:PageData?.banner?.image?.url
  }

//new ggfx image
  const getImage =
  PageData?.banner?.image?.url
      ? PageData?.banner?.image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && PageData?.ggfx_results?.length > 0
      ? PageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""


  // filter image with image size
  const getOGImg =
    filterImage?.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: PageData.strapi_id,
      field: "metaog",
      contentType: "page"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //preview seo dynamic updates

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
            if(parsedData?.metaTitle){
              setPreMetaTitle(parsedData?.metaTitle)
            }
          }
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} haus & haus`;
    }
}, [preMetaTitle]);
  //

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl


  const preloadImage =
  filterImage?.length > 0 && filterImage[0]?.transforms?.length > 0
    ? filterImage[0].transforms.filter((item) => item.transform === "1800x720"&&item.format==="webp")
    : ""

  const preloadMobile =  filterImage?.length > 0 && filterImage[0]?.transforms?.length > 0
  ? filterImage[0].transforms.filter((item) => item.transform === "376x667"&&item.format==="webp")
  : ""

  return (
    <>
      <link rel="preload" href={preloadMobile?.length>0 && preloadMobile[0]?.url} as="image" type="image/webp"  /> 
      <link rel="preload" href={preloadImage?.length>0&&preloadImage[0]?.url} as="image" type="image/webp"  /> 
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl={pageImage}
      />
    </>
  )
}


export default LandingPageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER {
          __typename
          id
          image {
            url
          }
          heading
          content {
            data {
              content
            }
          }
          description {
            data {
              description
            }
          }
          video_url
          title
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT {
          __typename
          ...IconFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }

         ... on STRAPI__COMPONENT_COMPONENTS_TEXT_LIST {
         __typename
            id
            item
            item_type
            title
          }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          __typename
          id
          heading

          items {
            cta_title
            content {
              data {
                content
              }
            }
            image {
              url
              alternativeText
            }
            link {
              ...MenuFragment
            }
            cta_title
            link_type
            custom_link
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_PODCAST_CARD {
          __typename
            id
            title
            cta {
              title
              custom_link
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
            content {
              data {
                content
              }
            }
          }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          id
          __typename
          type
          title
          content {
            data {
              content
            }
          }
          count
          cta_item {
            link {
              slug
              strapi_parent {
                slug
              }
            }
            title
            content {
              data {
                content
              }
            }
          }
          add_image {
            images {
              url
              alternativeText
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          id
          select_module
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          id
          left_right_block {
            menu {
              slug
              strapi_parent {
                slug
              }
            }
            image {
              url
              alternativeText
            }
            title
            description {
              data {
                description
              }
            }
            video_url
            layout
            cta_label
            custom_link
            bg_type
            id_name
          }
        }
      }
    }

    allProperties(
      filter: { publish: { eq: true }, search_type: {eq: "sales"}}
      sort: {fields: updatedAt, order: DESC}
      limit:4
    ) {
      edges {
        node {
          title
          crm_id
          area
          slug
          department
          status
          price
          bedroom
          building
          display_address
          description
          bathroom
         
          ggfx_results {
            src_cftle
            transforms {
              url
              format
              transform
            }
          }
          images {
            url
            srcUrl
          }
          search_type
          strapi_id
          floorarea_type
          floorarea_min
        }
      }
    }

    
    allStrapiNewDevelopments(
        filter: { publish: { eq: true } }
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            slug
            strapi_id
            crm_id
            price
            createdAt
            whatsapp
            images {
              strapi_json_value {
                url
              }
            }
            banner_title
            ggfx_results {
            src_cftle
              transforms {
                format
                url
                transform
              }
            }
            area
            expected_completion_date
            developer
          }
        }
      }


    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          video_url
          read_time
          date(formatString: "DD MMM, yy")
          strapi_id
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          imagetransforms {
            tile_image_Transforms
          }
        }
      }
    }
  }
`