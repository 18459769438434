import React, { useState } from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import PlayVideo from "../PlayVideo/PlayVideo"
import "./SecondaryPageBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"
import GGFXImage from "../../modules/GGFXImage"
import getVideoId from "get-video-id"


const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const SecondaryPageBanner = ({ data, pageData, isPreviewEnabled, isPropertyValuation, isLayoutcareer }) => {
  const [isPlay, setPlay] = useState(false)
  const { isMobile } = useDeviceMedia()

  const imagename = "page.secondary_banner.ban_image"

  const videoid = data?.video_url && getVideoId(data?.video_url)
  let youTubeImage=""
    if(videoid?.id){
      youTubeImage= `https://i.ytimg.com/vi/${videoid?.id}/maxresdefault.jpg`
    }


  return (
    <section className={`page-secondary-banner section_items ${isPropertyValuation?"property_val-block":""}
     ${isLayoutcareer?"career_youtube-block":""}`} id="video">
      {(data.image?.url || data?.video_url) &&
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container className={isMobile ? "custom-area-container" : ""}>
        <div className="area_section_containers">
          <div className="secondary-banner_section">
            <div className="image-section">
            {data?.video_url?
              <img className="thumbnail" src={youTubeImage} alt={data.title}/>
              :
               <GGFXImage
                  ImageSrc={data.image}
                  altText={`${
                    data.image?.alternativeText
                      ? data.image?.alternativeText
                      : data.title
                  }`}
                  imagetransforms={pageData.ggfx_results}
                  renderer="srcSet"
                  imagename={imagename}
                  strapiID={pageData?.strapi_id}
                />
              }
              <div className="overlay-above"/>
              <div className="banner-contents">
                {data.video_url && (
                  <button onClick={() => setPlay(true)} className="play-button">
                    <i className="icon video-filled-icon" />
                  </button>
                )}
                  {!data.video_url && (
                  <div>
                    {data.title&&
                      <h2 className="banner_title">{data.title}</h2>
                    }
                    {(data?.content?.data?.content || (isPreviewEnabled && data?.content)) &&
                    <p className="description">
                      <ContentModule Content={data?.content?.data?.content || data?.content} />
                    </p>
                    }
                  </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      </ScrollAnimation>
      }
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
      {(data.heading || data?.description?.data?.description) &&
        <div className="content-sections">
          <h2 className="heading">{data.heading}</h2>
          {(data?.description?.data?.description || (isPreviewEnabled && data?.description)) &&
          <p className="description">
            <ContentModule Content={data?.description?.data?.description || data?.description} />
          </p>
          }
        </div>
        }
      </Container>
      </ScrollAnimation>
      <PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={data?.video_url}
        htmlink={""}
      />
    </section>
  )
}

export default SecondaryPageBanner
