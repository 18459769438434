import React from "react"
import { Container } from "react-bootstrap"
import "./LandingBannerCard.scss"
const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const LandingBannerCard = ({ data }) => {
  return (
    <div className="landing-card_wrapper">
      <Container>
        <div className="banner_landing-card">
          <div className="sub-content">
            <h3>{data?.title}</h3>
            <p className="description">
              <ContentModule Content={data?.content?.data?.content} />
            </p>
          </div>
          <PreviewCTALink
            class="button button-filled-green"
            link={
              data?.cta?.link
                ? data?.cta?.link
                : { external_link: data?.cta?.custom_link }
            }
            title={data?.cta?.title}
          />
        </div>
      </Container>
    </div>
  )
}

export default LandingBannerCard