import React from "react"
import { Container } from "react-bootstrap"
import "./GridGallery.scss"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const GridGallery = ({ data }) => {
  return (
    <div className="grid-gallery-text-module">
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="text-section_landing">
          <h2 className="heading">{data?.title}</h2>
          <p className="description">
            <ContentModule Content={data?.content?.data?.content} />
          </p>
          <div className="image-grid">
            {data?.add_image?.map((list, index) => {
              return (
                <div className="image-list">
                  <img src={list?.images?.url} alt="Grid Gallery - haus & haus" />
                </div>
              )
            })}
          </div>
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default GridGallery
