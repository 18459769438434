import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NewsCard from "../NewsCard/NewsCard";
import '../NewsTab/NewsTab.scss';
import CustomSliderNews from "../CustomSlider/CustomSliderNews";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "gatsby";
const { PreviewCTALink } = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")
const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const ValNewsSlider = (props) => {
    const sortedNews=props.data?.sort((a,b)=>{
        return new Date(b.node?.date) - new Date(a.node?.date)
    })

    const valSlider=sortedNews?.length>0?sortedNews?.slice(0,4):""
    return (
            <div className={`val-news-slider-section news-tab-wrapper 
            ${props.isMortgageNews?"mortgage_slider-container":""}`}>           
            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
            <Container>
                <Row>
                    <Col>
                    <h3>{props?.title}</h3>
                    <div className="d-flex align-items-start justify-content-center">
                    {(props.content?.data?.content || (props.isPreviewEnabled&&props.content)) && (
                      <ContentModule Content={props.content?.data?.content || props.content} /> 
                    )}
                    <PreviewCTALink class="button inline-btn" link={props.add_cta_item?.link} title={props.add_cta_item?.title} target_window={props.add_cta_item} />
                    </div>
                    {props.isMortgageNews&&
                       <div className="mortgage_news-wrapper"> 
                        <h4 className="mortgage_news-text">{props.sliderTitle}</h4> 
                        <Link to="/resources/latest-news-and-insights/">
                            View All
                        </Link> 
                      </div>
                    }
                        <CustomSliderNews className={`news-card-wrapper-main-space ${props.isMortgageNews? "mortgage_slider-section":""}`}>
                        {valSlider?.map(({ node }, index) => {
                                return (
                                    <NewsCard data={node} />
                                )
                            }
                            )}
                            </CustomSliderNews>
                    </Col>
                </Row>
            </Container>
            </ScrollAnimation>
        </div>
    )
}

export default ValNewsSlider